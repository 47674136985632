import { Text, TextInput } from '@mantine/core';
import { useContext } from 'react';
import FormContext from '../../../contexts/FormContext';
import { FormFieldId, FormReducerAction } from '../../../contexts/FormReducer';
import { SizedBox } from '../../SizedBox/SizedBox';
import { StepContainer } from '../../StepContainer/StepContainer';

export const TechnicalContact = () => {
    const { state: { technicalName, technicalEmail }, dispatch } = useContext(FormContext);

    return (
        <StepContainer title="Technical Contact">
            <Text>The person who will be in contact with our technical team. This information will not be exposed to your users.</Text>
            <SizedBox height={16} />
            <TextInput
                placeholder="Technical Contact Name"
                value={technicalName.value}
                onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.technicalName, payload: e.target.value })}
                onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.technicalName })}
                error={technicalName.error}
            />
            <SizedBox height={8} />
            <TextInput
                placeholder="techcontact@yourcompany.com"
                value={technicalEmail.value}
                onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.technicalEmail, payload: e.target.value })}
                onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.technicalEmail })}
                error={technicalEmail.error}
            />
        </StepContainer>
    );
};
