import React, { useReducer } from 'react';
import { formReducer, FormReducerAction, IFormReducerState, initialFormReducerState, IReducerAction } from './FormReducer';

interface IFormContext {
    state: IFormReducerState;

    dispatch: (action: IReducerAction<FormReducerAction>) => void;
}

const defaultFormContextValues: IFormContext = {
    state: initialFormReducerState(),

    dispatch: () => { },
};

export const FormContext = React.createContext(defaultFormContextValues);

export const FormContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(formReducer, initialFormReducerState());

    return (
        <FormContext.Provider value={{
            state,

            dispatch,
        }}>
            {children}
        </FormContext.Provider>
    );
}

export default FormContext;
