import en from "../intl/en.json";

import React, { useCallback } from 'react';

interface ITranslationContext {
    t: (key: string) => string;
}

const defaultTranslationContextValues: ITranslationContext = {
    t: (key) => key,
};

export const TranslationContext = React.createContext(defaultTranslationContextValues);

export const TranslationContextProvider = ({ children }: { children: React.ReactNode }) => {
    const t = useCallback((key: string) => {
        return (en as any)[key] ?? key;
    }, []);

    return (
        <TranslationContext.Provider value={{
            t,
        }}>
            {children}
        </TranslationContext.Provider>
    );
}

export default TranslationContext;
