import { validAddress, validColor, validEmail } from "../utils/validators";

export enum FormFieldId {
    companyName = "companyName",
    companyDomain = "companyDomain",
    primaryColor = "primaryColor",
    secondaryColor = "secondaryColor",
    logoImage = "logoImage",
    favIcon = "favIcon",
    infoName = "infoName",
    infoDescription = "infoDescription",
    infoEmail = "infoEmail",
    infoAddress1 = "infoAddress1",
    infoAddress2 = "infoAddress2",
    infoPhoneNumber = "infoPhoneNumber",
    languages = "languages",
    noReplyEmail = "noReplyEmail",
    technicalName = "technicalName",
    technicalEmail = "technicalEmail",
}

export enum FormFieldType {
    NonEmptyString,
    NonEmptyArray,
    Url,
    Color,
    Image,
    Email,
}

export interface IReducerAction<ActionType> {
    type: ActionType;
    field: FormFieldId;
    payload?: any;
}

export enum FormReducerAction {
    Update = "Update",
    Blur = "Blur",
}

interface IField<T> {
    value: T;
    type: FormFieldType;
    invalid: boolean;
    error: boolean;
}

function field<T>(value: T, type: FormFieldType, invalid: boolean = true): IField<T> {
    return {
        value,
        type,
        invalid,
        error: false,
    };
}

export interface IFormReducerState {
    companyName: IField<string>;
    companyDomain: IField<string>;
    primaryColor: IField<string>;
    secondaryColor: IField<string>;
    logoImage: IField<IImageFieldValue>;
    favIcon: IField<IImageFieldValue>;
    infoName: IField<string>;
    infoDescription: IField<string>;
    infoEmail: IField<string>;
    infoAddress1: IField<string>;
    infoAddress2: IField<string>;
    infoPhoneNumber: IField<string>;
    languages: IField<string[]>;
    noReplyEmail: IField<string>;
    technicalName: IField<string>;
    technicalEmail: IField<string>;
}

interface IImageFieldValue {
    blob?: Blob;
    dataUrl?: string;
}

export const initialFormReducerState: () => IFormReducerState = () => ({
    companyName: field("", FormFieldType.NonEmptyString),
    companyDomain: field("", FormFieldType.Url),
    primaryColor: field("#C5D899", FormFieldType.Color, false),
    secondaryColor: field("#E5A899", FormFieldType.Color, false),
    logoImage: field({} as IImageFieldValue, FormFieldType.Image, false),
    favIcon: field({} as IImageFieldValue, FormFieldType.Image, false),
    infoName: field("", FormFieldType.NonEmptyString),
    infoDescription: field("", FormFieldType.NonEmptyString),
    infoEmail: field("", FormFieldType.Email),
    infoAddress1: field("", FormFieldType.NonEmptyString),
    infoAddress2: field("", FormFieldType.NonEmptyString),
    infoPhoneNumber: field("", FormFieldType.NonEmptyString),
    languages: field(["english"], FormFieldType.NonEmptyArray),
    noReplyEmail: field("", FormFieldType.Email),
    technicalName: field("", FormFieldType.NonEmptyString),
    technicalEmail: field("", FormFieldType.Email),
});

function isFieldInvalid(value: any, type: FormFieldType): boolean {
    switch (type) {
        case FormFieldType.NonEmptyString:
            return ((value as string).trim() === "");
        case FormFieldType.Url:
            return (!validAddress(value as string));
        case FormFieldType.Color:
            return (!validColor(value as string));
        case FormFieldType.Image:
            return !((value as IImageFieldValue).blob);
        case FormFieldType.NonEmptyArray:
            return (value as any[]).length === 0;
        case FormFieldType.Email:
            return (!validEmail(value as string));
        default:
            console.log('Unimplemented isFieldInvalid called on:', type);
            return true;
    }
}

export const formReducer = (state: IFormReducerState, action: IReducerAction<FormReducerAction>): IFormReducerState => {
    const s = { ...state };

    console.log(action);

    switch (action.type) {
        case FormReducerAction.Update:
            s[action.field] = {
                value: action.payload,
                type: s[action.field].type,
                invalid: isFieldInvalid(s[action.field].value, s[action.field].type),
                error: false,
            };
            if (s[action.field].type === FormFieldType.Color) {  // Mantine color input dispatches onChanged after onBlur, so extra check needed here
                if (action.payload) {
                    s[action.field].value = (action.payload as string).toUpperCase();
                }
                s[action.field].error ||= isFieldInvalid(action.payload, FormFieldType.Color);
                s[action.field].invalid = s[action.field].error;
            }
            break;
        case FormReducerAction.Blur:
            const invalid = isFieldInvalid(s[action.field].value, s[action.field].type);
            s[action.field] = {
                value: s[action.field].value as any,
                type: s[action.field].type,
                invalid,
                error: invalid,
            }
            break;
        default:
            console.log(`Unknown action: ${action.payload}`);
            break;
    }

    console.log(s);

    return s;
}
