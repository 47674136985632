import { Group, Text } from '@mantine/core';
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { openConfirmModal } from '@mantine/modals';
import { IconPhoto } from '@tabler/icons';
import "cropperjs/dist/cropper.css";
import { useContext, useEffect, useRef, useState } from 'react';
import { Cropper, ReactCropperElement } from 'react-cropper';
import FormContext from '../../../contexts/FormContext';
import { FormFieldId, FormReducerAction } from '../../../contexts/FormReducer';
import { SizedBox } from '../../SizedBox/SizedBox';
import { StepContainer } from '../../StepContainer/StepContainer';

interface IUploadedFile {
    file: FileWithPath;
    url: string;
}

export const CompanyIcons = () => {
    const { state: { logoImage, favIcon }, dispatch } = useContext(FormContext);
    const cropper = useRef<ReactCropperElement>(null);
    const [uploadedFile, setUploadedFile] = useState<IUploadedFile | null>(null);
    const [uploadedFieldId, setUploadedFieldId] = useState<FormFieldId>(FormFieldId.logoImage);

    const handleFileDrop = (files: FileWithPath[], fieldId: FormFieldId) => {
        if (files.length < 1) return;
        const file = files[0];
        const url = URL.createObjectURL(file);
        setUploadedFieldId(fieldId);
        setUploadedFile({ file, url });
    }

    useEffect(() => {
        if (!uploadedFile || !uploadedFile.url || !uploadedFile.file) return;
        onImageUploaded();
    }, [uploadedFile])

    const onImageUploaded = () => openConfirmModal({
        title: 'Please crop your image:',
        children: (
            <Cropper
                ref={cropper}
                src={uploadedFile?.url}
                style={{ height: 400, width: '100%', maxHeight: '80%' }}
                aspectRatio={uploadedFieldId === FormFieldId.logoImage ? 444 / 84 : 1}
                guides={false}
                crop={() => { }}
                allowTransparency={true}
            />
        ),
        labels: { confirm: 'Confirm', cancel: 'Cancel' },
        onCancel: () => {
            setUploadedFile((uploadedFile) => {
                if (uploadedFile?.url) {
                    URL.revokeObjectURL(uploadedFile.url);
                }
                return null;
            });
        },
        onConfirm: () => {
            const canvas = cropper.current?.cropper?.getCroppedCanvas();
            const dataUrl = canvas?.toDataURL("image/png");
            canvas?.toBlob((blob) => {
                dispatch({
                    type: FormReducerAction.Update,
                    field: uploadedFieldId,
                    payload: { blob, dataUrl },
                });
                dispatch({
                    type: FormReducerAction.Blur,
                    field: uploadedFieldId,
                });
            });
            setUploadedFile((uploadedFile) => {
                if (uploadedFile?.url) {
                    URL.revokeObjectURL(uploadedFile.url);
                }
                return null;
            });
        },
    });

    return (
        <StepContainer title="Your Company Logos and Fave Icon">
            <Text>Provide a high resolution image with transparency (png or svg). </Text>
            <SizedBox height={8} />
            <Text>Company Logo </Text>
            <SizedBox height={8} />
            <Dropzone
                accept={IMAGE_MIME_TYPE}
                onDrop={(files) => handleFileDrop(files, FormFieldId.logoImage)}
                onReject={() => { }}
                maxSize={3 * 1024 ** 2}
                style={{ maxWidth: '100%' }}
            >
                <Group position="center" spacing="xl" style={{ width: 480, minHeight: 100, maxWidth: '98%', maxHeight: '100%', pointerEvents: 'none', flexDirection: 'column', alignItems: 'center' }}>
                    {logoImage?.value?.dataUrl &&
                        <img src={logoImage.value.dataUrl} style={{ width: 444, height: 88, maxWidth: '98%', maxHeight: '100%' }} />
                    }

                    {!(logoImage?.value?.dataUrl) &&
                        <>
                            <IconPhoto size={50} stroke={1.5} />
                            <Text size={"xl"} inline>
                                Drop the image of your company's logo here, or click to select
                            </Text>
                            <Text size="sm" color="dimmed" inline mt={7}>
                                Attach your Company Logo
                            </Text>
                        </>
                    }
                </Group>
            </Dropzone>
            <SizedBox height={16} />
            <Text>Company Favicon </Text>
            <SizedBox height={8} />
            <Dropzone
                accept={IMAGE_MIME_TYPE}
                onDrop={(files) => handleFileDrop(files, FormFieldId.favIcon)}
                onReject={() => { }}
                maxSize={3 * 1024 ** 2}
                style={{ maxWidth: '100%' }}
            >
                <Group position="center" spacing="xl" style={{ width: 480, minHeight: 100, maxWidth: '98%', maxHeight: '100%', pointerEvents: 'none', flexDirection: 'column', alignItems: 'center' }}>
                    {favIcon?.value?.dataUrl &&
                        <img src={favIcon.value.dataUrl} style={{ width: 88, height: 88, maxWidth: '98%', maxHeight: '100%' }} />
                    }

                    {!(favIcon?.value?.dataUrl) &&
                        <>
                            <IconPhoto size={50} stroke={1.5} />
                            <Text size="xl" inline>
                                Drop the favicon of your company here, or click to select
                            </Text>
                            <Text size="sm" color="dimmed" inline mt={7}>
                                Attach your Company Logo
                            </Text>
                        </>
                    }
                </Group>
            </Dropzone>
        </StepContainer>
    );
};
