import { Checkbox, Text } from '@mantine/core';
import { useContext } from 'react';
import FormContext from '../../../contexts/FormContext';
import { FormFieldId, FormReducerAction } from '../../../contexts/FormReducer';
import { SizedBox } from '../../SizedBox/SizedBox';
import { StepContainer } from '../../StepContainer/StepContainer';
import styles from './Languages.module.css';

const allLanguages = ['english', 'french', 'spanish'];
const trans = {
    'english': 'English',
    'french': 'French',
    'spanish': 'Spanish',
};

export const Languages = () => {
    const { state: { languages }, dispatch } = useContext(FormContext);

    const toggleLanguage = (language: string) => {
        const newLanguages = languages.value.slice();
        const index = newLanguages.findIndex(l => l === language);
        if (index === -1) newLanguages.push(language);
        else newLanguages.splice(index, 1);
        dispatch({ type: FormReducerAction.Update, field: FormFieldId.languages, payload: newLanguages });
    }

    return (
        <StepContainer title="Languages">
            <Text>Select the languages your application will support.</Text>
            <SizedBox height={16} />
            <div className={styles.languagesContainer}>
                {allLanguages.map((lang, index) => (
                    <>
                        <Checkbox
                            checked={languages.value.includes(lang)}
                            onChange={() => toggleLanguage(lang)}
                            label={(trans as any)[lang]}
                        />
                        {index !== (allLanguages.length - 1) && <SizedBox height={8} />}
                    </>
                ))}
            </div>
        </StepContainer>
    );
};
