import { Text, Textarea, TextInput } from '@mantine/core';
import { useContext } from 'react';
import FormContext from '../../../contexts/FormContext';
import { FormFieldId, FormReducerAction } from '../../../contexts/FormReducer';
import { SizedBox } from '../../SizedBox/SizedBox';
import { StepContainer } from '../../StepContainer/StepContainer';
import styles from './CompanyInfo.module.css';

export const CompanyInfo = () => {
    const { state: { infoName, infoDescription, infoEmail, infoAddress1, infoAddress2, infoPhoneNumber }, dispatch } = useContext(FormContext);

    return (
        <StepContainer title="Company Infomation">
            <Text>We may display information about you in the about box of your software. While this is optional, it increases the confidence of your visitors and increases the conversion rate.</Text>
            <div className={styles.row}>
                <div className={styles.col}>
                    <TextInput
                        label="Company Name"
                        placeholder="Your Company Name"
                        value={infoName.value}
                        onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.infoName, payload: e.target.value })}
                        onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.infoName })}
                        error={infoName.error}
                    />
                    <SizedBox height={8} />
                    <Textarea
                        label="Company Application Description"
                        placeholder="Company Application Description"
                        value={infoDescription.value}
                        onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.infoDescription, payload: e.target.value })}
                        onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.infoDescription })}
                        error={infoDescription.error}
                    />
                    <SizedBox height={8} />
                    <TextInput
                        label="Contact Email"
                        placeholder="contact@yourcompany.com"
                        value={infoEmail.value}
                        onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.infoEmail, payload: e.target.value })}
                        onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.infoEmail })}
                        error={infoEmail.error}
                    />
                    <SizedBox height={8} />
                    <TextInput
                        label="Address line 1"
                        placeholder=""
                        value={infoAddress1.value}
                        onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.infoAddress1, payload: e.target.value })}
                        onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.infoAddress1 })}
                        error={infoAddress1.error}
                    />
                    <SizedBox height={8} />
                    <TextInput
                        label="Address line 2"
                        placeholder=""
                        value={infoAddress2.value}
                        onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.infoAddress2, payload: e.target.value })}
                        onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.infoAddress2 })}
                        error={infoAddress2.error}
                    />
                    <SizedBox height={8} />
                    <TextInput
                        label="Phone number"
                        placeholder="+000000000"
                        value={infoPhoneNumber.value}
                        onChange={(e) => dispatch({ type: FormReducerAction.Update, field: FormFieldId.infoPhoneNumber, payload: e.target.value })}
                        onBlur={() => dispatch({ type: FormReducerAction.Blur, field: FormFieldId.infoPhoneNumber })}
                        error={infoPhoneNumber.error}
                    />
                </div>
                <div className={styles.col} />
            </div>
        </StepContainer>
    );
};
