import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { App } from './App/App';
import { FormContextProvider } from './contexts/FormContext';
import { TranslationContextProvider } from './contexts/TranslationContext';

function AppWrapper() {
    return (
        <TranslationContextProvider>
            <MantineProvider withGlobalStyles withNormalizeCSS>
                <ModalsProvider>
                    <FormContextProvider>
                        <App />
                    </FormContextProvider>
                </ModalsProvider>
            </MantineProvider>
        </TranslationContextProvider>
    );
}

export default AppWrapper;
