import { Center, Stepper } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useContext, useState } from 'react';
import { SizedBox } from "../components/SizedBox/SizedBox";
import { StepButtons } from "../components/StepButtons/StepButtons";
import { AppInfo } from "../components/Steps/AppInfo/AppInfo";
import { BrandColors } from "../components/Steps/BrandColors/BrandColors";
import { CompanyIcons } from "../components/Steps/CompanyIcons/CompanyIcons";
import { CompanyInfo } from "../components/Steps/CompanyInfo/CompanyInfo";
import { Languages } from "../components/Steps/Languages/Languages";
import { NoReply } from "../components/Steps/NoReply/NoReply";
import { TechnicalContact } from "../components/Steps/TechnicalContact/TechnicalContact";
import TranslationContext from "../contexts/TranslationContext";
import styles from "./App.module.css";

export const App = () => {
    const { t } = useContext(TranslationContext);
    const isXS = useMediaQuery('(max-width: 576px)');

    const [currentStep, setCurrentStep] = useState(0);
    const nextStep = () => setCurrentStep((current) => (current < 7 ? current + 1 : current));
    const prevStep = () => setCurrentStep((current) => (current > 0 ? current - 1 : current));

    return (
        <div className={styles.container}>
            <Stepper
                breakpoint="xs"
                color="dark"
                size="xs"
                active={currentStep}
                onStepClick={setCurrentStep}
            >
                <Stepper.Step allowStepSelect={currentStep < 7} label={isXS && t("Application Information")}>
                    <AppInfo />
                </Stepper.Step>
                <Stepper.Step allowStepSelect={currentStep < 7} label={isXS && t("Brand Colors")}>
                    <BrandColors />
                </Stepper.Step>
                <Stepper.Step allowStepSelect={currentStep < 7} label={isXS && t("Company Information")}>
                    <CompanyIcons />
                </Stepper.Step>
                <Stepper.Step allowStepSelect={currentStep < 7} label={isXS && t("Languages")}>
                    <Languages />
                </Stepper.Step>
                <Stepper.Step allowStepSelect={currentStep < 7} label={isXS && t("Additional Information")}>
                    <NoReply />
                </Stepper.Step>
                <Stepper.Step allowStepSelect={currentStep < 7} label={isXS && t("Company Information")}>
                    <CompanyInfo />
                </Stepper.Step>
                <Stepper.Step allowStepSelect={currentStep < 7} label={isXS && t("Technical Contact")}>
                    <TechnicalContact />
                </Stepper.Step>
                <Stepper.Completed>
                    <Center>
                        {t('thank you')}
                    </Center>
                </Stepper.Completed>
            </Stepper>
            <SizedBox height={16} />
            <StepButtons
                currentStep={currentStep}
                prevStep={prevStep}
                nextStep={nextStep}
            />
        </div>
    );
}
